import React from 'react'
import { Container, Grid, Card, CardContent, Typography, CardMedia, Box } from '@mui/material'
import { Twitter, Facebook, Instagram, LinkedIn } from '@mui/icons-material'
import SocialIcon from './SocialIcon'

interface Member {
	img: string
	name: string
	role?: string
	description?: string
}

interface SectionProps {
	sectionTitle: string
	sectionSubtitle?: string
	members: Member[]
	isTeamSection: boolean
}

const Section: React.FC<SectionProps> = ({ sectionTitle, sectionSubtitle, members, isTeamSection }) => {
	return (
		<Container
			data-aos="fade-up"
			sx={{
				padding: '20px'
			}}>
			<Box textAlign="center" mb={4}>
				<Card
					sx={{
						margin: '0 auto',
						borderRadius: '8px',
						boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
						backgroundColor: '#ffffff',
						border: '1px solid #e0e0e0'
					}}>
					<CardContent>
							<Typography variant="h3"  textAlign="center">
								{sectionTitle}
							</Typography>
								<Typography variant="subtitle1" color="textSecondary" textAlign="center">
									{sectionSubtitle}
								</Typography>
					</CardContent>
				</Card>
			</Box>

			<Grid container spacing={3} justifyContent="center" data-aos="fade-up" data-aos-delay="100">
				{members.map((member, index) => (
					<Grid item xs={12} md={4} key={index}>
						<Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									padding: '20px',
									height: '100%'
								}}>
								<CardMedia
									component="img"
									image={member.img}
									alt={member.name}
									sx={{
										width: '150px',
										height: '150px',
										objectFit: 'cover',
										marginBottom: '20px',
										borderRadius: '8px',
										boxShadow: '0 0 10px rgba(200, 200, 200, 0.5)'
									}}
								/>
								<CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
									<Typography variant="h5" gutterBottom style={{ fontWeight: 'bold' }}>
										{member.name}
									</Typography>
									<Typography variant="subtitle1" gutterBottom>
										{member.role}
									</Typography>
									<Typography variant="body1" paragraph>
										{member.description}
									</Typography>
								</CardContent>
								<Box sx={{ mt: 'auto', display: 'flex', justifyContent: 'center', gap: 1 }}>
									<SocialIcon icon={<Twitter />} />
									<SocialIcon icon={<Facebook />} />
									<SocialIcon icon={<Instagram />} />
									<SocialIcon icon={<LinkedIn />} />
								</Box>
							</Box>
						</Card>
					</Grid>
				))}
			</Grid>
		</Container>
	)
}

export default Section
