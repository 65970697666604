import { Container, Grid, Box, CardContent, Card } from '@mui/material'
import { LocationOn, Email, Phone } from '@mui/icons-material'
import Navbar from '../Navbar'
import Footer from '../../shared/Footer'
import ContactInfo from './ContactInfo'
import { useTranslation } from '../../translation/useTranslations'

const Contact = () => {
	const { translations } = useTranslation()
	const contactPage = translations.Contact

	return (
		<>
			<Navbar />
			<Box className="repeating-pattern">
				<Box id="contact" component="section">
					<Container sx={{ padding: '40px' }}>
						<Grid container spacing={4} alignItems="center">
							<Grid item xs={12}>
								<Box sx={{ width: '100%', height: 340, overflow: 'hidden' }}>
									<iframe
										title="Brasov"
										src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d89245.21660731666!2d25.514062063486694!3d45.65256847861281!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b35b862aa214f1%3A0x6cf5f2ef54391e0f!2zQnJhyJlvdg!5e0!3m2!1sro!2sro!4v1716837043329!5m2!1sro!2sro"
										frameBorder="0"
										style={{ border: 0, width: '100%', height: '100%', borderRadius: '8px' }}
										allowFullScreen></iframe>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Card>
									<CardContent>
										<Grid container spacing={3} alignItems="center">
											<Grid item xs={12} lg={4}>
												<ContactInfo
													icon={<LocationOn />}
													title={`${contactPage.location}:`}
													info="Brasov, Brasov, Romania"
												/>
											</Grid>
											<Grid item xs={12} lg={4}>
												<ContactInfo icon={<Email />} title="Email:" info="" />
											</Grid>
											<Grid item xs={12} lg={4}>
												<ContactInfo icon={<Phone />} title={`${contactPage.phone}:`} info="(+40) 748 044 152" />
											</Grid>
										</Grid>
									</CardContent>
								</Card>
							</Grid>
						</Grid>
					</Container>
				</Box>
			</Box>
			<Footer />
		</>
	)
}

export default Contact
