import React from 'react'
import { Typography, Box, Card } from '@mui/material'
import { useTranslation } from '../../translation/useTranslations'

interface AboutContentProps {
	hideBox?: boolean
}

const AboutContent: React.FC<AboutContentProps> = ({ hideBox }) => {
	const { translations } = useTranslation()
	const aboutContentTranslation = translations.aboutContent

	const AboutText = () => (
		<>
			<Typography variant="h3" component="h2" gutterBottom>
				{aboutContentTranslation.aboutUs}
			</Typography>
			<Typography variant="body1" gutterBottom>
				{aboutContentTranslation.aboutUsDescription1}
			</Typography>
			{!hideBox && (
				<Typography variant="body1" gutterBottom>
					{aboutContentTranslation.aboutUsDescription2}
				</Typography>
			)}
		</>
	)

	return (
		<>
			<Card
				sx={{
					height: '100%',
					borderRadius: '8px',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center'
				}}>
				<Box sx={{ p: 2 }}>
					<AboutText />
				</Box>
			</Card>
		</>
	)
}

export default AboutContent
