import React, { useState } from 'react'
import { Grid, Card, Box, Typography, Container, CardContent, IconButton } from '@mui/material'
import { People, Assignment, AccessTime, Group, PlayArrowOutlined, PlayArrow } from '@mui/icons-material'
import AboutContent from './AboutContent'
import ServiceIcons from './ServiceIcons'
import Testimonials from '../../shared/Testimonials'
import FAQ from '../../shared/FAQ'
import { useTranslation } from '../../translation/useTranslations'
import { useTranslatedData } from '../../Data/Data'
import Navbar from '../Navbar'
import Footer from '../../shared/Footer'
import WhyUsSection from './WhyUsSection'

interface Testimonial {
	img: string
	name: string
	position: string
	content: string
	rating: number
}

const About: React.FC = () => {
	const { translations } = useTranslation()

	const AboutPage = translations.AboutPage
	const { testimonials } = useTranslatedData()
	const [isPlaying, setIsPlaying] = useState(false)

	const handlePlayPause = () => {
		setIsPlaying(!isPlaying)
	}

	return (
		<Box className="repeating-pattern">
			<Navbar />
			<Container sx={{ padding: '20px' }}>
				<Box id="about" component="section" sx={{ paddingTop: '20px' }}>
					<Container>
						<Grid container spacing={3}>
							<Grid item xs={12} data-aos="fade-up">
								<AboutContent hideBox={true} />
							</Grid>

							<Grid item xs={12} data-aos="fade-up" data-aos-delay={100}>
								<Box
									sx={{
										position: 'relative',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										borderRadius: '8px', // Round margins
										overflow: 'hidden' // Hide overflow content
									}}>
									<iframe
										src="https://www.youtube.com/embed/erJTobmqxBw"
										title="Cargo Share video"
										frameBorder="0"
										allowFullScreen
										style={{
											width: '100%',
											height: '500px', // Adjust height as needed
											borderRadius: '8px' // Round margins
										}}></iframe>
								</Box>
							</Grid>
						</Grid>
						<Grid item xs={1} data-aos="fade-up" sx={{ py: 4 }}>
							<ServiceIcons />
						</Grid>
						<WhyUsSection />
					</Container>
					<Box sx={{ flexGrow: 1, padding: 4 }}>
						<Grid container spacing={4}>
							<Grid item xs={12} sm={6} md={3}>
								<Card sx={{ height: '100%' }}>
									<CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
										<People sx={{ fontSize: 60, color: 'primary.main', marginBottom: 2 }} />
										<Typography variant="h5" gutterBottom>
											232
										</Typography>
										<Typography variant="body1" color="textSecondary">
											{AboutPage.clients}
										</Typography>
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs={12} sm={6} md={3}>
								<Card sx={{ height: '100%' }}>
									<CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
										<Assignment sx={{ fontSize: 60, color: 'primary.main', marginBottom: 2 }} />
										<Typography variant="h5" gutterBottom>
											521
										</Typography>
										<Typography variant="body1" color="textSecondary">
											{AboutPage.projects}
										</Typography>
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs={12} sm={6} md={3}>
								<Card sx={{ height: '100%' }}>
									<CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
										<AccessTime sx={{ fontSize: 60, color: 'primary.main', marginBottom: 2 }} />
										<Typography variant="h5" gutterBottom>
											1453
										</Typography>
										<Typography variant="body1" color="textSecondary">
											{AboutPage.hoursOfSupport}
										</Typography>
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs={12} sm={6} md={3}>
								<Card sx={{ height: '100%' }}>
									<CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
										<Group sx={{ fontSize: 60, color: 'primary.main', marginBottom: 2 }} />
										<Typography variant="h5" gutterBottom>
											32
										</Typography>
										<Typography variant="body1" color="textSecondary">
											{AboutPage.workers}
										</Typography>
									</CardContent>
								</Card>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Container>
			<Testimonials testimonials={testimonials as Testimonial[]} />
			<Container sx={{ paddingBottom: '10px' }}>
				<FAQ />
			</Container>
			<Footer />
		</Box>
	)
}

export default About
