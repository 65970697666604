import { useTranslation } from '../translation/useTranslations'
import { ShoppingCart, LocalShippingOutlined, InventoryRounded } from '@mui/icons-material'

export const useTranslatedData = () => {
	const { translations } = useTranslation()
	const dataTranslation = translations
	const steps = [
		{
			label: dataTranslation.steps.step_label_one,
			description: dataTranslation.steps.step_description_one
		},
		{
			label: dataTranslation.steps.step_label_two,
			description: dataTranslation.steps.step_description_two
		},
		{
			label: dataTranslation.steps.step_label_three,
			description: dataTranslation.steps.step_description_three
		},
		{
			label: dataTranslation.steps.step_label_four,
			description: dataTranslation.steps.step_description_four
		}
	]
	const common = {
		learnMore: dataTranslation.common.learnMore,
		faq: dataTranslation.common.faq,
		tryNow: dataTranslation.common.tryNow,
		whyUs: dataTranslation.common.whyUs
	}
	const collaborationSection = {
		collaborators: dataTranslation.collaborators_section.collaborators_section_collaborators,
		collaboratorsDescription: dataTranslation.collaborators_section.collaborators_section_description
	}
	const teamSection = {
		teamTitle: dataTranslation.team_section.team_title,
		teamSubtitle: dataTranslation.team_section.team_subtitle
	}
	const plans = [
		{
			title: dataTranslation.plans.plan_title_one,
			features: dataTranslation.plans.plan_features_one
		},
		{
			title: dataTranslation.plans.plan_title_two,
			features: dataTranslation.plans.plan_features_two
		}
	]
	const whyUsReasons = [
		{
			title: dataTranslation.whyUsReasons.reason_title_one,
			description: dataTranslation.whyUsReasons.reason_description_one
		},
		{
			title: dataTranslation.whyUsReasons.reason_title_two,
			description: dataTranslation.whyUsReasons.reason_description_two
		},
		{
			title: dataTranslation.whyUsReasons.reason_title_three,
			description: dataTranslation.whyUsReasons.reason_description_three
		}
	]
	const accordionData = [
		{
			id: 'faq-header-1',
			contentId: 'faq-content-1',
			title: dataTranslation.accordionData.accordion_data_title_one,
			description: dataTranslation.accordionData.accordion_data_description_one
		},
		{
			id: 'faq-header-2',
			contentId: 'faq-content-2',
			title: dataTranslation.accordionData.accordion_data_title_two,
			description: dataTranslation.accordionData.accordion_data_description_two
		},
		{
			id: 'faq-header-3',
			contentId: 'faq-content-3',
			title: dataTranslation.accordionData.accordion_data_title_three,
			description: dataTranslation.accordionData.accordion_data_description_three
		},
		{
			id: 'faq-header-4',
			contentId: 'faq-content-4',
			title: dataTranslation.accordionData.accordion_data_title_four,
			description: dataTranslation.accordionData.accordion_data_description_three
		},
		{
			id: 'faq-header-5',
			contentId: 'faq-content-5',
			title: dataTranslation.accordionData.accordion_data_title_five,
			description: dataTranslation.accordionData.accordion_data_description_five
		}
	]

	const collaboratorsMembers = [
		{
			img: '/team/Ioana_Stan.jpg',
			name: 'Ioana Stan',
			role: dataTranslation.collaboratorsMembers.role_first_member
		},
		{
			img: '/team/Bianca_Stefanescu.jpg',
			name: 'Bianca Stefanescu',
			role: dataTranslation.collaboratorsMembers.role_second_member
		},
		{
			img: '/team/Diana_Viga.jpg',
			name: 'Diana Viga',
			role: dataTranslation.collaboratorsMembers.role_third_member
		}
	]

	const teamMembers = [
		{
			img: '/team/Sergiu_Balcanu.jpg',
			name: 'Sergiu Balcanu',
			description: dataTranslation.teamMembers.description_member_first
		},
		{
			img: '/team/Ionut_Tudor.jpg',
			name: 'Ionut Tudor',
			description: dataTranslation.teamMembers.description_member_second
		},
		{
			img: '/team/Andrei_Iacob.jpg',
			name: 'Andrei Iacob',
			description: dataTranslation.teamMembers.description_member_third
		}
	]
	const services = [
		{
			title: dataTranslation.services.service_one_title,
			description: dataTranslation.services.service_one_description,
			image: '/services/share-cargo.png'
		},
		{
			title: dataTranslation.services.service_two_title,
			description: dataTranslation.services.service_two_description,
			image: '/services/logistics-service.jpg'
		},
		{
			title: dataTranslation.services.service_three_title,
			description: dataTranslation.services.service_three_description,
			image: '/services/cargo-service.png'
		},
		{
			title: dataTranslation.services.service_four_title,
			description: dataTranslation.services.service_four_description,
			image: '/services/management-fleet.png'
		},
		{
			title: dataTranslation.services.service_five_title,
			description: dataTranslation.services.service_five_description,
			image: '/services/packaging-service.png'
		},
		{
			title: dataTranslation.services.service_six_title,
			description: dataTranslation.services.service_six_description,
			image: '/services/warehousing-service.jpg'
		}
	]

	const testimonials = [
		{
			img: '/testimonials/testimonials-1.jpg',
			name: 'Saul Goodman',
			position: dataTranslation.userTestimonials.testimonial_one_position,
			content: dataTranslation.userTestimonials.testimonial_one_content,
			rating: 5
		},
		{
			img: '/testimonials/testimonials-2.jpg',
			name: 'Sara Wilsson',
			position: dataTranslation.userTestimonials.testimonial_two_position,
			content: dataTranslation.userTestimonials.testimonial_two_content,
			rating: 5
		},
		{
			img: '/testimonials/testimonials-3.jpg',
			name: 'Jena Karlis',
			position: dataTranslation.userTestimonials.testimonial_three_position,
			content: dataTranslation.userTestimonials.testimonial_three_content
		},
		{
			img: '/testimonials/testimonials-4.jpg',
			name: 'Matt Brandon',
			position: dataTranslation.userTestimonials.testimonial_four_position,
			content: dataTranslation.userTestimonials.testimonial_four_content,
			rating: 5
		},
		{
			img: '/testimonials/testimonials-5.jpg',
			name: 'John Larson',
			position: dataTranslation.userTestimonials.testimonial_five_position,
			content: dataTranslation.userTestimonials.testimonial_five_content,
			rating: 5
		}
	]

	const features = [
		{
			title: dataTranslation.features.feature_title_one,
			description: dataTranslation.features.feature_description_one,
			image: '/features/features-1.png'
		},
		{
			title: dataTranslation.features.feature_title_two,
			description: dataTranslation.features.feature_description_two,
			image: '/features/features-2.jpg'
		},
		{
			title: dataTranslation.features.feature_title_three,
			description: dataTranslation.features.feature_description_three,
			image: '/features/features-3.jpg'
		},
		{
			title: dataTranslation.features.feature_title_four,
			description: dataTranslation.features.feature_description_four,
			image: '/features/features-4.jpg'
		}
	]
	const servicesIcons = [
		{
			icon: <ShoppingCart fontSize="large" />,
			title: dataTranslation.serviceIcons.service_icon_title_first,
			description: dataTranslation.serviceIcons.service_icon_description_first
		},
		{
			icon: <LocalShippingOutlined fontSize="large" />,
			title: dataTranslation.serviceIcons.service_icon_title_two,
			description: dataTranslation.serviceIcons.service_icon_description_two
		},
		{
			icon: <InventoryRounded fontSize="large" />,
			title: dataTranslation.serviceIcons.service_icon_title_three,
			description: dataTranslation.serviceIcons.service_icon_description_three
		}
	]
	return {
		dataTranslation,
		plans,
		whyUsReasons,
		accordionData,
		features,
		testimonials,
		services,
		teamMembers,
		collaboratorsMembers,
		common,
		servicesIcons,
		collaborationSection,
		teamSection,
		steps
	}
}
