import React from 'react'
import { Container, Grid, Typography, Link, Box } from '@mui/material'
import { Facebook, LinkedIn } from '@mui/icons-material'
import { useTranslation } from '../translation/useTranslations'
import { webConfig } from '../config'

const Footer: React.FC = () => {
	const { translations } = useTranslation()
	const footerPage = translations.Footer
	return (
		<Box
			sx={{
				backgroundColor: '#0e1d34',
				color: 'common.white',
				padding: theme => theme.spacing(6),
				pt: 3
			}}>
			<Container>
				<Grid container spacing={6}>
					<Grid item xs={12} md={6}>
						<Typography variant="h4" component="div" gutterBottom>
							{webConfig.name}
						</Typography>
						<Typography variant="body1" gutterBottom>
							{footerPage.descriptionCargo}
						</Typography>
						<Box sx={{ display: 'flex', gap: 1 }}>
							<Link
								href="https://www.facebook.com/share/dmcqq8MUkToE7XtE/"
								color="inherit"
								aria-label="facebook"
								sx={{ textDecoration: 'none' }}>
								<Facebook />
							</Link>
							<Link
								href="https://www.linkedin.com/company/cargo-share/"
								color="inherit"
								aria-label="linkedin"
								sx={{ textDecoration: 'none' }}>
								<LinkedIn />
							</Link>
						</Box>
					</Grid>
					<Grid item xs={6} sm={2}>
						<Typography variant="h6" component="h4" gutterBottom>
							{footerPage.usefulLinks}
						</Typography>
						<ul style={{ listStyleType: 'none', padding: 0 }}>
							{['home', 'aboutUs', 'services', 'termsOfService', 'privacyPolicy'].map(linkKey => (
								<li key={linkKey}>
									<Link href="#" color="inherit" sx={{ textDecoration: 'none' }}>
										{footerPage[linkKey]}
									</Link>
								</li>
							))}
						</ul>
					</Grid>
					<Grid item xs={6} sm={2}>
						<Typography variant="h6" component="h4" gutterBottom>
							{footerPage.ourServices}
						</Typography>
						<ul style={{ listStyleType: 'none', padding: 0 }}>
							{Object.entries(footerPage.servicesList).map(([index, service]: [string, string]) => (
								<li key={index}>
									<Link href="#" color="inherit" sx={{ textDecoration: 'none' }}>
										{service}
									</Link>
								</li>
							))}
						</ul>
					</Grid>
					<Grid item xs={12} md={2}>
						<Typography variant="h6" component="h4" gutterBottom>
							{footerPage.contactUs}
						</Typography>
						<Box sx={{ marginBottom: 2 }}>
							<Typography variant="body1" gutterBottom>
								Brasov, Romania
							</Typography>
							<Typography variant="body1" gutterBottom>
								<Box component="span" fontWeight="fontWeightBold">
									{footerPage.phone}
								</Box>
								(+40)748044152
							</Typography>
							<Typography variant="body1" gutterBottom>
								<Box component="span" fontWeight="fontWeightBold">
									{footerPage.email}
								</Box>
							</Typography>
						</Box>
					</Grid>
				</Grid>
			</Container>
			<Container>
				<Typography variant="body2" align="center" sx={{ mt: 4 }}>
					&copy; {new Date().getFullYear()} <strong>{webConfig.name}</strong>
				</Typography>
			</Container>
		</Box>
	)
}

export default Footer
