import Navbar from './Navbar'
import Footer from '../shared/Footer'
import { Typography, Container, Box, Grid, Paper, CardContent, Card } from '@mui/material'
import ShortIntro from '../shared/ShortIntro'
import { useTranslatedData } from '../Data/Data'
import ServiceCards from '../shared/ServiceCards'
import FlowDiagram from '../shared/FlowDiagram'

const Services = () => {
	const { features } = useTranslatedData()

	return (
		<Box className="repeating-pattern">
			<Navbar />
			<Container sx={{ padding: '20px 20px 0 20px' }}>
				<ShortIntro />
				<ServiceCards />
			</Container>
			<Box
				id="features"
				className="features"
				sx={{ px: { xs: '20px', md: '40px' }, pt: { xs: '20px', md: '40px' }, pb: { xs: '20px' } }}>
				<Container>
					{features.map((feature, index) => (
						<Grid container spacing={4} key={index} data-aos="fade-up" sx={{ mb: 4 }}>
							<Grid item xs={12} data-aos={index % 2 === 0 ? 'fade-right' : 'fade-left'}>
								<Card variant="outlined" sx={{ p: 2, borderRadius: 2 }}>
									<CardContent>
										<Box
											sx={{
												display: 'flex',
												flexDirection: { xs: 'column', md: index % 2 === 0 ? 'row' : 'row-reverse' }
											}}>
											<Box
												sx={{
													flex: 1,
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													mb: { xs: 2, md: 0 }
												}}>
												<img
													src={feature.image}
													alt=""
													style={{
														width: '100%',
														maxWidth: '400px',
														height: 'auto',
														borderRadius: '16px',
														objectFit: 'cover'
													}}
												/>
											</Box>
											<Box
												sx={{
													flex: 1,
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													px: { xs: 0, md: 4 }
												}}>
												<Box sx={{ pt: { xs: 2, md: 0 } }}>
													<Typography variant="h5" gutterBottom>
														{feature.title}
													</Typography>
													<Typography variant="body1" className="fst-italic" gutterBottom>
														{feature.description}
													</Typography>
												</Box>
											</Box>
										</Box>
									</CardContent>
								</Card>
							</Grid>
						</Grid>
					))}
				</Container>
			</Box>
			<FlowDiagram />
			<Footer />
		</Box>
	)
}

export default Services
