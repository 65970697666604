import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import { useTranslation } from '../translation/useTranslations'
import useMediaQuery from '@mui/material/useMediaQuery'
import { NavDrawer } from '../ui/navigation/navDrawer'
import { NavAppBar } from '../ui/navigation/navAppBar'
import { NavMainContent } from '../ui/navigation/navMainContent'

const Navbar: React.FC = () => {
	const { translations } = useTranslation()
	const navbarTranslation = translations.Navbar
	const isSmallScreen = useMediaQuery('(max-width: 1040px)')
	const [drawerOpen, setDrawerOpen] = useState(false)

	useEffect(() => {
		document.body.style.overflowX = 'hidden'
		return () => {
			document.body.style.overflowX = 'auto'
		}
	}, [])

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundImage: 'url("/background.jpg")',
				backgroundSize: 'cover',
				padding: '20px',
				borderRadius: '8px',
				height: '100vh',
				textAlign: 'center',
				overflowX: 'hidden'
			}}>
			<NavMainContent isSmallScreen={isSmallScreen} navbarTranslation={navbarTranslation} />

			<NavAppBar isSmallScreen={isSmallScreen} setDrawerOpen={setDrawerOpen} navbarTranslation={navbarTranslation} />
			<NavDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} navbarTranslation={navbarTranslation} />
		</Box>
	)
}

export default Navbar
