import React from 'react'
import { Box } from '@mui/material'
import { ReactElement } from 'react'

interface SocialIconProps {
	icon: ReactElement
}

const SocialIcon: React.FC<SocialIconProps> = ({ icon }) => {
	return (
		<Box mx={1}>
			<a href="#">{icon}</a>
		</Box>
	)
}

export default SocialIcon
