import { useEffect } from 'react'
import { Helmet } from 'react-helmet'

export const GoogleAnalytics = () => {
	const gaTag = 'G-S8KQY6YP05'

	useEffect(() => {
		console.log('initializing ga4')

		window.dataLayer = window.dataLayer || []
		function gtag(...args) {
			window.dataLayer.push(...args)
		}

		gtag('js', new Date())

		gtag('config', gaTag)
	}, [])

	return (
		<Helmet>
			<script async src={`https://www.googletagmanager.com/gtag/js?id=${gaTag}`} referrerPolicy="no-referrer" />
		</Helmet>
	)
}
