import React from 'react'
import Navbar from './Navbar'
import Footer from '../shared/Footer'
import {
	Box,
	Card,
	CardContent,
	Typography,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Button,
	Container
} from '@mui/material'
import { Check } from '@mui/icons-material'
import { useTranslatedData } from '../Data/Data'

const Pricing = () => {
	const { plans, common } = useTranslatedData()
	const buttonLabel = common.tryNow

	return (
		<Box className="repeating-pattern">
			<Navbar />
			<Container
				sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', padding: '30px' }}>
				{plans.map((plan, index) => (
					<Card
						key={index}
						sx={{
							width: '300px',
							margin: '10px',
							borderRadius: '8px',
							boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
							transition: 'transform 0.3s',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
							'&:hover': {
								transform: 'scale(1.05)'
							}
						}}>
						<CardContent sx={{ flex: '1 0 auto', display: 'flex', flexDirection: 'column' }}>
							<Typography variant="h5" sx={{ mb: 2 }}>
								{plan.title}
							</Typography>
							{plan.features && plan.features.trim() && (
								<List>
									{plan.features.split(',').map(
										(feature: string, idx: React.Key | null | undefined) =>
											feature.trim() && (
												<ListItem key={idx}>
													<ListItemIcon>
														<Check />
													</ListItemIcon>
													<ListItemText primary={feature.trim()} />
												</ListItem>
											)
									)}
								</List>
							)}
							<Box sx={{ mt: 'auto', display: 'flex', justifyContent: 'center' }}>
								<Button href="https://platform.example.eu" variant="contained" color="primary">
									{buttonLabel}
								</Button>
							</Box>
						</CardContent>
					</Card>
				))}
			</Container>
			<Footer />
		</Box>
	)
}

export default Pricing
