import React from 'react';
import { Typography, Paper, Grid } from '@mui/material';
import {
  Money,
  EnergySavingsLeaf,
  OnlinePrediction,
  ReduceCapacity,
  Visibility,
  AirportShuttle,
} from '@mui/icons-material';
import { useTranslation } from '../translation/useTranslations';

interface ServiceItemProps {
  icon: React.ReactElement;
  title: string;
  description: string;
}

const ServiceItem: React.FC<ServiceItemProps> = ({ icon, title, description }) => {
  return (
    <Grid sx={{ marginTop: '10px' }} item xs={12} md={4} data-aos="fade-up">
      <Paper elevation={3} sx={{ p: 2, borderRadius: '8px', textAlign: 'center', height: '100%' }}>
        {icon}
        <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>{title}</Typography>
        <Typography variant="body2" gutterBottom sx={{ fontSize: '0.8rem' }}>{description}</Typography>
      </Paper>
    </Grid>
  );
};

const ShortIntro: React.FC = () => {
  const { translations } = useTranslation();
  const shortIntroTranslation = translations.shortIntro;

  const serviceItems: ServiceItemProps[] = [
    {
      icon: <Money fontSize="large" />,
      title: shortIntroTranslation.localShippingTitle,
      description: shortIntroTranslation.localShippingDescription
    },
    {
      icon: <EnergySavingsLeaf fontSize="large" />,
      title: shortIntroTranslation.fireTruckTitle,
      description: shortIntroTranslation.fireTruckDescription
    },
    {
      icon: <OnlinePrediction fontSize="large" />,
      title: shortIntroTranslation.storefrontTitle,
      description: shortIntroTranslation.storefrontDescription
    },
    {
      icon: <ReduceCapacity fontSize="large" />,
      title: shortIntroTranslation.reduceCapacityTitle,
      description: shortIntroTranslation.reduceCapacityDescription
    },
    {
      icon: <Visibility fontSize="large" />,
      title: shortIntroTranslation.visibilityTitle,
      description: shortIntroTranslation.visibilityDescription
    },
    {
      icon: <AirportShuttle fontSize="large" />,
      title: shortIntroTranslation.airportShuttleTitle,
      description: shortIntroTranslation.airportShuttleDescription
    }
  ];

  return (
    <Grid sx={{ paddingTop: '10px' }} container spacing={4}>
      {serviceItems.map((item, index) => (
        <ServiceItem key={index} {...item} />
      ))}
    </Grid>
  );
};

export default ShortIntro;
