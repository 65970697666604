import { Container, Typography, Box, Button } from '@mui/material';
import { useTranslation } from '../translation/useTranslations';

const CallToAction = () => {
  const { translations } = useTranslation();
  const callToActionTranslation = translations.callToActionPage;

  return (
    <>
      <Box
        sx={{
          background: 'linear-gradient(rgba(14, 29, 52, 0.6), rgba(14, 29, 52, 0.8)), url("/cta-bg.jpg") center center',
          backgroundSize: 'cover',
          padding: '100px 0',
          py: 4,
          color: 'white'
        }}
        component="section"
      >
        <Container data-aos="zoom-out">
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h3">{callToActionTranslation.callToAction}</Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              {callToActionTranslation.bodyCallToAction}
            </Typography>
            <Button variant="contained" color="primary" size="large">
              {callToActionTranslation.learnMoreButton}
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CallToAction;
