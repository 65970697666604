import React from 'react'
import { Typography, Box, Grid, Card, CardContent, CardMedia } from '@mui/material'
import { useTranslatedData } from '../Data/Data'
import { useTranslation } from '../translation/useTranslations'

interface Service {
	title: string
	description: string
	image: string
}

const ServiceCards: React.FC = () => {
	const { services } = useTranslatedData()
	const { translations } = useTranslation()
	const aboutPageTranslation = translations.AboutPage

	return (
		<Box mt={6} textAlign="center" sx={{ paddingBottom: '20px' }}>
			<Typography variant="h3" gutterBottom>
				{aboutPageTranslation.ourServices}
			</Typography>
			<Grid container spacing={3} justifyContent="center">
				{services.map((service: Service, index: number) => (
					<Grid item key={index} xs={12} sm={6} md={4} style={{ display: 'flex' }}>
						<Card style={{ display: 'flex', flexDirection: 'column', height: '100%', borderRadius: '8px' }}>
							<CardMedia component="img" height="200" image={service.image} alt={service.title} />
							<CardContent style={{ flex: 1 }}>
								<Typography gutterBottom variant="h5" component="div">
									{service.title}
								</Typography>
								<Typography variant="body2">{service.description}</Typography>
							</CardContent>
						</Card>
					</Grid>
				))}
			</Grid>
		</Box>
	)
}

export default ServiceCards
