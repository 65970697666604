import { Typography, Grid, Button } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { motion } from 'framer-motion'
import { Link, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { webConfig } from '../config'
import { DirectionsCar } from '@mui/icons-material'
import { useTranslation } from '../translation/useTranslations'
function Confirm() {
	const location = useLocation()
	const { translations } = useTranslation()
	const cargoShareInfo = translations.Navbar
	const confirmData = translations.ConfirmPage
	const [userName, setUserName] = useState<string>('')

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search)
		const userId = searchParams.get('userId')
		const token = searchParams.get('token')
		postConfirmation(userId, token)
	}, [location])

	const postConfirmation = async (userId: string, token: string) => {
		try {
			const response = await fetch(webConfig.apiUrl, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ userId, token })
			})
			const data = await response.json()
			setUserName(data.Value.Name)
		} catch (error) {
			console.log('Error: ', error)
		}
	}
	return (
		<Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh', background: '#f0f2f5' }}>
			<Grid item xs={10} sm={6} md={4}>
				<motion.div
					initial={{ opacity: 0, scale: 0.5 }}
					animate={{ opacity: 1, scale: 1 }}
					transition={{ duration: 0.5 }}
					style={{
						boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.1)',
						borderRadius: 16,
						background: '#fff',
						padding: 20,
						border: '2px solid #e0e0e0',
						textAlign: 'center',
						margin: '20px'
					}}>
					<CheckCircleIcon sx={{ fontSize: 64, color: '#4caf50' }} />
					<Typography variant="h5" gutterBottom style={{ marginTop: 16 }}>
						{`${confirmData.confirmTitle} ${userName}!`}
					</Typography>
					<Typography variant="body1" style={{ marginBottom: 20 }}>
						{confirmData.confirmationDescription}
					</Typography>
				</motion.div>
				<motion.div
					initial={{ opacity: 0, scale: 0.5 }}
					animate={{ opacity: 1, scale: 1 }}
					transition={{ duration: 0.5 }}
					style={{
						boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.1)',
						borderRadius: 16,
						background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
						padding: 20,
						textAlign: 'center',
						color: 'white',
						border: '2px solid #cfd8dc',
						margin: '20px'
					}}>
					<DirectionsCar sx={{ fontSize: 64, marginBottom: '20px' }} />
					<Typography variant="h5" gutterBottom>
						{cargoShareInfo.navTitle}
					</Typography>
					<Typography variant="body1" style={{ marginBottom: 20 }}>
						{cargoShareInfo.navDescription}
					</Typography>
					<Button
						variant="contained"
						component={Link}
						to={`${webConfig.portalUrl}`}
						sx={{
							color: 'white',
							marginTop: '20px'
						}}>
						Portal
					</Button>
				</motion.div>
			</Grid>
		</Grid>
	)
}

export default Confirm
