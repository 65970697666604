import React from 'react'
import { Typography, Box, IconButton } from '@mui/material'

const ContactInfo = ({ icon, title, info }: { icon: React.ReactElement; title: string; info: string }) => {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
			<IconButton sx={{ mr: 1 }} color="primary">
				{icon}
			</IconButton>
			<Box>
				<Typography variant="h6">{title}</Typography>
				<Typography variant="body1">{info}</Typography>
			</Box>
		</Box>
	)
}

export default ContactInfo
