import React, { useState, useEffect } from 'react'
import { Typography, Box } from '@mui/material'
import Carousel from 'react-material-ui-carousel'
import Rating from '@mui/material/Rating'
import { useTranslation } from '../translation/useTranslations'

interface Testimonial {
	img: string
	name: string
	position: string
	content: string
	rating: number
}

interface Props {
	testimonials: Testimonial[]
}

const Testimonials: React.FC<Props> = ({ testimonials }) => {
	const { translations } = useTranslation()
	const testimonialTranslation = translations.Testimonials

	const [activeIndex, setActiveIndex] = useState(0)

	useEffect(() => {
		const interval = setInterval(() => {
			setActiveIndex(prevIndex => (prevIndex + 1) % testimonials.length)
		}, 5000)
		return () => clearInterval(interval)
	}, [testimonials])

	if (!testimonials || testimonials.length === 0) {
		return (
			<Typography variant="h6" align="center">
				No testimonials available
			</Typography>
		)
	}

	return (
		<Box
			sx={{
				backgroundSize: 'cover',
				padding: '100px 0',
				py: 4,
				backgroundColor: '#f4f4f4'
			}}>
			<Typography variant="h4" align="center" gutterBottom>
				{testimonialTranslation.testimonials}
			</Typography>
			<Carousel autoPlay={false} animation="slide" index={activeIndex}>
				{testimonials.map((testimonial, index) => (
					<Box
						key={index}
						sx={{
							background:
								'linear-gradient(rgba(14, 29, 52, 0.6), rgba(14, 29, 52, 0.8)), url("/cta-bg.jpg") center center',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							padding: '20px',
							textAlign: 'center',
							height: '400px',
							margin: 'auto',
							transition: 'opacity 0.5s ease-in-out',
							opacity: activeIndex === index ? 1 : 0
						}}>
						<Box
							component="img"
							src={testimonial.img}
							alt={testimonial.name}
							className="testimonial-img"
							sx={{
								width: '150px',
								height: '150px',
								objectFit: 'cover',
								marginBottom: '20px',
								borderRadius: '50%',
								boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)'
							}}
						/>
						<Typography variant="h5" align="center" color="white" gutterBottom>
							{testimonial.name}
						</Typography>
						<Typography variant="subtitle1" align="center" color="white" gutterBottom>
							{testimonial.position}
						</Typography>
						<Typography variant="body1" align="center" color="white" gutterBottom>
							{testimonial.content}
						</Typography>
						<Rating name="read-only" value={testimonial.rating} readOnly />
					</Box>
				))}
			</Carousel>
		</Box>
	)
}

export default Testimonials
