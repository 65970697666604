import Footer from '../shared/Footer'
import Navbar from './Navbar'
import CallToAction from '../shared/CallToAction'
import FAQ from '../shared/FAQ'
import { Container, Typography, Box, Button } from '@mui/material'
import React from 'react'
import { useTranslation } from '../translation/useTranslations'

const NotFound: React.FC = () => {
	const { translations } = useTranslation()
	const notFoundPage = translations.PageNotFound
	return (
		<Box className="repeating-pattern">
			<Navbar />
			<Container sx={{ padding: '20px', textAlign: 'center' }}>
				<Typography variant="h4" color="error" gutterBottom>
					{notFoundPage.notFoundMessage}
				</Typography>
				<Typography variant="body1" paragraph>
					{notFoundPage.notFoundDescription}
				</Typography>
				<Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
					<Button href="/" variant="contained" color="primary" size="large">
						Go to home
					</Button>
				</Box>
			</Container>
			<CallToAction />
			<Container sx={{ paddingBottom: '10px' }}>
				<FAQ />
			</Container>
			<Footer />
		</Box>
	)
}
export default NotFound
