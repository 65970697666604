import { useRecoilState } from 'recoil'
import { atom } from 'recoil'
import Localization from 'react-localization'

export const languageState = atom({
	key: 'languageState',
	default: 'en'
})

export const useTranslation = () => {
	const [language, setLanguage] = useRecoilState(languageState)
	const strings = new Localization({
		en: require('../locales/en.json'),
		ro: require('../locales/ro.json')
	})

	strings.setLanguage(language)

	const updateLanguage = async (newLanguage: string) => {
		try {
			const translations = await import(`../locales/${newLanguage}.json`)
			setLanguage(newLanguage)
			strings.setLanguage(newLanguage)
			return translations
		} catch (error) {
			console.error(`Translation for ${newLanguage} is not available.`, error)
			return null
		}
	}

	return { translations: strings, updateLanguage }
}
