import { useState } from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useTranslatedData } from '../Data/Data'
import { useTranslation } from '../translation/useTranslations'

const FlowDiagram = () => {
	const { translations } = useTranslation()
	const stepsTitle = translations.steps.title
	const [activeStep, setActiveStep] = useState(0)
	const { steps } = useTranslatedData()

	const handleNext = () => {
		setActiveStep(prevActiveStep => Math.min(prevActiveStep + 1, steps.length - 1))
	}

	return (
		<Box sx={{ paddingBottom: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			<Typography variant="h5" gutterBottom>
				{stepsTitle}
			</Typography>
			<Box
				sx={{
					display: 'flex',
					flexDirection: { xs: 'column', md: 'row' },
					justifyContent: 'center',
					alignItems: 'center',
					paddingTop: 1,
					paddingBottom: 2,
					flexWrap: 'wrap'
				}}>
				{steps.map((step, index) => (
					<Box key={index} sx={{ margin: 1 }}>
						<Paper
							elevation={3}
							sx={{
								p: 2,
								borderRadius: 2,
								textAlign: 'center',
								height: 200,
								width: 200,
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between'
							}}>
							<div>
								<Typography variant="subtitle1" gutterBottom>
									{step.label}
								</Typography>
								<Typography variant="body2" gutterBottom>
									{step.description}
								</Typography>
							</div>
							{index < steps.length - 1 && (
								<Box sx={{ alignSelf: 'flex-end' }}>
									<ArrowForwardIcon sx={{ cursor: 'pointer' }} onClick={handleNext} />
								</Box>
							)}
						</Paper>
					</Box>
				))}
			</Box>
		</Box>
	)
}

export default FlowDiagram
