import Footer from '../shared/Footer'
import Navbar from './Navbar'
import { Container, Box } from '@mui/material'
import CallToAction from '../shared/CallToAction'
import FAQ from '../shared/FAQ'
import SectionWithMembers from '../shared/SectionWithMembers'
import { useTranslatedData } from '../Data/Data'

export const Team = () => {
	const { collaborationSection, collaboratorsMembers } = useTranslatedData()
	const { teamSection, teamMembers } = useTranslatedData()

	return (
		<Box className="repeating-pattern">
			<Navbar />
			<Container sx={{ padding: '20px' }}>
				<SectionWithMembers
					sectionTitle={teamSection?.teamTitle}
					sectionSubtitle={teamSection?.teamSubtitle}
					members={teamMembers}
					isTeamSection={true}
				/>
				<SectionWithMembers
					sectionTitle={collaborationSection?.collaborators}
					sectionSubtitle={collaborationSection?.collaboratorsDescription}
					members={collaboratorsMembers}
					isTeamSection={false}
				/>
			</Container>
			<CallToAction />
			<Container sx={{ paddingBottom: '10px' }}>
				<FAQ />
			</Container>
			<Footer />
		</Box>
	)
}
export default Team
