import { Button, ButtonProps } from '@mui/material'
import { Link, LinkProps } from 'react-router-dom'
import { logGa } from '../../tracking/logging/logGa'
import { LogGaProps } from '../../tracking/types/logGaProps'

interface NavButtonProps extends ButtonProps {
	to: LinkProps['to']
	label: string
	gaEvent?: LogGaProps
}

export const NavButton: React.FC<NavButtonProps> = ({ to, label, gaEvent, ...props }: NavButtonProps) => {
	const handleClick = () => {
		if (gaEvent) {
			logGa(gaEvent)
		}
	}

	return (
		<Button component={Link} to={to} color="inherit" onClick={handleClick} {...props}>
			{label}
		</Button>
	)
}
