import React, { MouseEvent, KeyboardEvent as ReactKeyboardEvent } from 'react'
import { Box, Drawer, List } from '@mui/material'
import LanguageSelector from '../../translation/LanguageSelector'
import { NavListItem } from '../../ui/navigation/navListItem'
import ListItem from '@material-ui/core/ListItem'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@mui/material/styles'

interface DrawerComponentProps {
	drawerOpen: boolean
	setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
	navbarTranslation: any
}

export const NavDrawer: React.FC<DrawerComponentProps> = ({ drawerOpen, setDrawerOpen, navbarTranslation }) => {
	const theme = useTheme()
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

	const handleDrawerClick = (event: MouseEvent) => {
		setDrawerOpen(false)
	}

	const handleDrawerKeyDown = (event: ReactKeyboardEvent) => {
		if (event.key === 'Tab' || event.key === 'Shift') {
			return
		}
		setDrawerOpen(false)
	}

	return (
		<Drawer
			anchor="left"
			open={drawerOpen}
			onClose={() => setDrawerOpen(false)}
			sx={{ '& .MuiDrawer-paper': { width: 250 } }}>
			<Box
				component="div"
				sx={{ width: 250, padding: '20px' }}
				onClick={handleDrawerClick}
				onKeyDown={handleDrawerKeyDown}>
				<Box mb={4}>
					<List>
						<NavListItem to="/" primary={navbarTranslation.home} gaEvent={{ event: 'home_list_item_clicked' }} />
						<NavListItem to="/about" primary={navbarTranslation.about} gaEvent={{ event: 'about_list_item_clicked' }} />
						<NavListItem to="/team" primary={navbarTranslation.team} gaEvent={{ event: 'team_list_item_clicked' }} />
						<NavListItem
							to="/services"
							primary={navbarTranslation.services}
							gaEvent={{ event: 'services_list_item_clicked' }}
						/>
						<NavListItem
							to="/pricing"
							primary={navbarTranslation.pricing}
							gaEvent={{ event: 'pricing_list_item_clicked' }}
						/>
						<NavListItem
							to="/contact"
							primary={navbarTranslation.contact}
							gaEvent={{ event: 'contact_list_item_clicked' }}
						/>
						<ListItem>
							<LanguageSelector translationStyle={isSmallScreen ? { color: 'black' } : { color: 'white' }} />
						</ListItem>
					</List>
				</Box>
			</Box>
		</Drawer>
	)
}
