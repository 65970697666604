import { Typography, Box, Grid, Paper } from '@mui/material'
import { useTranslatedData } from '../../Data/Data'

const WhyUsSection = () => {
	const { whyUsReasons, common } = useTranslatedData()

	return (
		<Box py={4}>
			<Typography variant="h4" align="center" gutterBottom>
				{common.whyUs}
			</Typography>
			<Grid container spacing={4} justifyContent="center">
				{whyUsReasons.map((item, index) => (
					<Grid item key={index} xs={12} sm={6} md={4}>
						<Paper elevation={3} sx={{ p: 2, borderRadius: '8px', textAlign: 'center', height: '100%' }}>
							<Typography variant="h6" gutterBottom>
								{item.title}
							</Typography>
							<Typography variant="body1" gutterBottom>
								{item.description}
							</Typography>
						</Paper>
					</Grid>
				))}
			</Grid>
		</Box>
	)
}

export default WhyUsSection
