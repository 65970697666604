import React from 'react'
import { Box, Typography } from '@mui/material'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import { useTranslatedData } from '../Data/Data'
import { styled } from '@mui/material/styles'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
	({ theme }) => ({
		border: `1px solid ${theme.palette.divider}`,
		'&:not(:last-child)': {
			borderBottom: 0
		},
		'&::before': {
			display: 'none'
		}
	})
)

const AccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ fontSize: '1.25rem' }} />} {...props} />
))(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, .03)' : 'rgba(255, 255, 255, .05)',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)'
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1)
	}
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: '1px  rgba(0, 0, 0, .125)'
}))

const FAQ = () => {
	const { accordionData, common } = useTranslatedData()

	const [expanded, setExpanded] = React.useState<string | false>(false)

	const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
		setExpanded(newExpanded ? panel : false)
	}

	return (
		<Box mt={4} sx={{ padding: '5px' }}>
			<Typography variant="h4" style={{ textAlign: 'center' }} gutterBottom>
				{common.faq}
			</Typography>
			{accordionData.map(item => (
				<Accordion key={item.id} expanded={expanded === item.id} onChange={handleChange(item.id)}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls={item.contentId}
						id={item.id}
						sx={{ borderBottom: '1px  #333' }}>
						<Box sx={{ flexGrow: 1, paddingLeft: '16px' }}>
							<Typography variant="subtitle1">{item.title}</Typography>
						</Box>
					</AccordionSummary>
					<AccordionDetails>
						<Typography variant="body1">{item.description}</Typography>
					</AccordionDetails>
				</Accordion>
			))}
		</Box>
	)
}

export default FAQ
