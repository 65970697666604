import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { webConfig } from '../../config'

interface MainContentComponentProps {
	isSmallScreen: boolean
	navbarTranslation: any
}

export const NavMainContent: React.FC<MainContentComponentProps> = ({ isSmallScreen, navbarTranslation }) => {
	return (
		<Box
			sx={{
				color: 'white',
				backgroundSize: 'cover',
				width: '100%',
				minHeight: '100vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				padding: '0 20px',
				overflow: 'hidden'
			}}>
			<Box
				component="div"
				sx={{
					background: 'rgba(14, 29, 60, 0.7)',
					borderRadius: '8px',
					padding: '20px',
					width: '100%', // Adjusted width for the typography box
					maxWidth: '900px',
					boxSizing: 'border-box',
					textAlign: 'center',
					margin: '0 auto 20px auto'
				}}>
				<Typography variant="h1" sx={{ fontSize: '4rem', transition: 'font-size 0.5s' }}>
					{navbarTranslation.navTitle}
				</Typography>
				<Typography
					variant="body1"
					sx={{ fontSize: '1.25rem', transition: 'font-size 0.5s', marginBottom: '20px' }}
					color="white">
					{navbarTranslation.navDescription}
				</Typography>
				<Button
					variant="contained"
					component={Link}
					to={`${webConfig.portalUrl}`}
					sx={{
						backgroundColor: '#0d42ff',
						color: 'white',
						marginTop: '20px',
						'&:hover': { backgroundColor: '#004494' },
						padding: '10px 20px'
					}}>
					Portal
				</Button>
			</Box>
			{!isSmallScreen && ( // Render the truck box only if it's not a small screen
				<Box
					component="div"
					sx={{
						width: '100%', // Adjusted width for the truck box
						maxWidth: '900px',
						boxSizing: 'border-box',
						textAlign: 'center',
						display: 'block'
					}}>
					<Box
						component="img"
						src="semi-truck-vectorportal.png"
						alt="Truck Image"
						sx={{
							width: '100%',
							height: 'auto',
							borderRadius: '8px',
							transition: 'width 0.5s'
						}}
					/>
				</Box>
			)}
		</Box>
	)
}
