import { logGa } from '../../tracking/logging/logGa'
import { LogGaProps } from '../../tracking/types/logGaProps'
import { ListItem , ListItemText } from '@mui/material';
import { Link, LinkProps } from 'react-router-dom'

interface NavListItemProps  {
	to: LinkProps['to']
	primary: string
	gaEvent?: LogGaProps
}

export const NavListItem: React.FC<NavListItemProps> = ({ to, primary, gaEvent,...props  }) => {
    const handleClick = () => {
      if (gaEvent) {
        logGa(gaEvent);
      }
    };
  
    return (
      
        <ListItem button component={Link} to={to} onClick={handleClick} {...props} >
					<ListItemText primary={primary} color='inherit'/>
      </ListItem>
    );
  };
  