import Footer from '../shared/Footer'
import Navbar from './Navbar'
import ShortIntro from '../shared/ShortIntro'
import AboutContent from './AboutPage/AboutContent'
import { Container, Box } from '@mui/material'
import ServiceCards from '../shared/ServiceCards'
import CallToAction from '../shared/CallToAction'
import FAQ from '../shared/FAQ'

export const Home = () => {
	return (
		<Box className="repeating-pattern">
			<Navbar />
			<Container sx={{ padding: '40px' }}>
				<Container>
					<AboutContent hideBox={false} />
					<ShortIntro />
					<ServiceCards />
				</Container>
			</Container>
			<CallToAction />
			<Container sx={{ paddingBottom: '10px' }}>
				<FAQ />
			</Container>
			<Footer />
		</Box>
	)
}
export default Home
