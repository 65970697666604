import React from 'react'
import { Typography, Box, IconButton, Grid, Paper } from '@mui/material'
import { useTranslatedData } from '../../Data/Data'
import { ArrowForward } from '@mui/icons-material'

interface ServiceItem {
	icon: React.ReactNode
	title: string
	description: string
}

const ServiceIcons: React.FC = () => {
	const { servicesIcons, common } = useTranslatedData()

	return (
		<Grid container spacing={3} sx={{ paddingBottom: '10px' }}>
			{servicesIcons?.map((service: ServiceItem, index: number) => (
				<Grid key={index} item xs={12} md={4} data-aos="fade-up" data-aos-delay={index * 100}>
					<Paper
						elevation={3}
						sx={{
							p: 2,
							borderRadius: '8px',
							textAlign: 'center',
							height: '100%',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between'
						}}>
						<Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
							{service.icon}
							<Typography variant="h6" gutterBottom className="title">
								{service.title}
							</Typography>
							<Typography variant="body2" className="description">
								{service.description}
							</Typography>
						</Box>
						<Box sx={{ mt: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							<Typography variant="body2">{common.learnMore}</Typography>
							<IconButton aria-label="learn more" href="#">
								<ArrowForward />
							</IconButton>
						</Box>
					</Paper>
				</Grid>
			))}
		</Grid>
	)
}

export default ServiceIcons
