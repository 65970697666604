import React from 'react'
import { languageState, useTranslation } from './useTranslations'
import { useRecoilState } from 'recoil'
import { Select, MenuItem, FormControl, InputBase } from '@mui/material'
import { styled } from '@mui/material/styles'
import { SelectChangeEvent } from '@mui/material'

interface TranslationStyleProps {
	translationStyle: React.CSSProperties
}

const CustomInput = styled(InputBase, {
	shouldForwardProp: prop => prop !== 'translationStyle'
})<TranslationStyleProps>(({ translationStyle }) => ({
	...translationStyle
}))

interface LanguageSelectorProps {
	translationStyle: React.CSSProperties
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ translationStyle }) => {
	const { updateLanguage } = useTranslation()
	const [language, setLanguage] = useRecoilState(languageState)
	const { translations } = useTranslation()
	const dropDownLanguage = translations.translationDropDown

	const handleChangeLanguage = (event: SelectChangeEvent<string>) => {
		const selectedLanguage = event.target.value as string
		updateLanguage(selectedLanguage)
		setLanguage(selectedLanguage)
	}

	return (
		<FormControl>
			<Select
				value={language}
				onChange={handleChangeLanguage}
				input={<CustomInput translationStyle={translationStyle} />}>
				<MenuItem value="en">{dropDownLanguage.english}</MenuItem>
				<MenuItem value="ro">{dropDownLanguage.romanian}</MenuItem>
			</Select>
		</FormControl>
	)
}

export default LanguageSelector
